<template>
  <div>
    <div class="page-header panel">
      <h3 class="title">Шинэ харилцагчийн хүсэлт</h3>
    </div>
    <el-row :gutter="0">
      <el-col :span="24" :offset="0">
        <div class="panel payments-container">
          <el-row :gutter="0">
            <el-col :span="24">
              <el-row>
                <el-col :span="21">
                  <el-form :inline="true" class="demo-form-inline" size="mini">
                    <el-row :gutter="20">
                      <el-col :span="24">
                        <el-form-item label="Харилцагчийн нэр">
                          <el-input
                            clearable
                            placeholder="Харилцагчийн нэр"
                            size="mini"
                            v-model="outletName"
                          />
                        </el-form-item>
                        <el-form-item label="И-мэйл">
                          <el-input
                            clearable
                            placeholder="И-мэйл"
                            size="mini"
                            v-model="email"
                          />
                        </el-form-item>
                        <el-form-item>
                          <el-button
                            type="primary"
                            icon="el-icon-search"
                            @click="newPartners(1)"
                            >Хайх</el-button
                          >
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </el-col>
              </el-row>
              <el-table :data="partnerList" style="width: 100%">
                <el-table-column type="index" label="№" width="80">
                </el-table-column>
                <el-table-column prop="full_name_mon" label="Харилцагчийн нэр">
                </el-table-column>
                <el-table-column
                  prop="created_at"
                  label="Хүсэлт илгээсэн хугацаа"
                >
                </el-table-column>
                <el-table-column prop="email" label="Харилцагчийн и-мэйл хаяг">
                </el-table-column>
                <el-table-column label="Үйлдэл">
                  <template slot-scope="scope">
                    <el-button
                      @click="detail(scope.row)"
                      type="primary"
                      size="mini"
                    >
                      Дэлгэрэнгүй
                    </el-button>
                    <el-button
                      @click="deleteDialog(scope.row)"
                      type="danger"
                      size="mini"
                    >
                      Устгах
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
              <!-- <el-pagination
                class="text-right mt10"
                background
                layout="sizes, prev, pager, next, total"
                :total="count"
                :page-sizes="[10, 20, 30, 40]"
                @current-change="setPage"
                @size-change="setPageSize"
              >
              </el-pagination> -->
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
    <el-dialog
      v-if="editPartner"
      :title="'Шинэ харилцагчийн мэдээлэл'"
      :visible.sync="isDetail"
      @close="close"
      width="60%"
    >
      <div>
        <div class="panel">
          <el-form :model="editPartner" label-width="40%" class="demo-ruleForm">
            <el-row>
              <el-col :span="12">
                <el-form-item label="Харилцагчийн нэр" prop="outlet_name">
                  <el-input
                    disabled
                    style="width:60%"
                    size="mini"
                    v-model="editPartner.full_name_eng"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Хүсэлт гаргасан огноо">
                  <el-input
                    disabled
                    style="width:60%"
                    size="mini"
                    v-model="editPartner.created_at"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="Үйлчилгээний төрөл" prop="service_list">
                  <el-input
                    disabled
                    style="width:60%"
                    size="mini"
                    v-model="editPartner.services"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Харилцагчийн хаяг" prop="address">
                  <el-input
                    disabled
                    style="width:60%"
                    size="mini"
                    v-model="editPartner.address"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item
                  label="Шимтгэл"
                  v-if="editPartner.commission_type === 'percent'"
                >
                  <el-input
                    disabled
                    style="width:60%"
                    size="mini"
                    v-model="editPartner.percent"
                  >
                    <template slot="append">%</template>
                  </el-input>
                </el-form-item>
                <el-form-item label="Шимтгэл" v-else>
                  <el-input
                    disabled
                    style="width:60%"
                    size="mini"
                    v-model="editPartner.percent"
                  >
                    <template slot="append">₮</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Хүргэлтийн хэлбэр">
                  <el-checkbox
                    disabled
                    label="Харилцагч өөрөө хүргэх эсэх"
                    v-model="editPartner.is_delivery_by_outlet"
                  ></el-checkbox>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item label="Гэрээний хавсралт материал" label-width="20%">
              <el-row type="flex" style="flex-wrap: wrap; gap: 5px">
                <div
                  v-for="(file, indexFile) in editPartner.files"
                  :key="indexFile"
                  class="avatar-uploader"
                  @click="handleItem(file)"
                >
                  <img
                    v-if="!file.includes('.pdf')"
                    :src="file"
                    class="avatar"
                  />
                  <img
                    v-else
                    src="https://image.toktok.mn/zwT06YogeQ1678848476921.png"
                    class="avatar"
                  />
                </div>
              </el-row>
            </el-form-item>
            <el-form-item label="Утасны дугаар">
              <el-table
                :data="editPartner.phone_list"
                style="width: 100%"
                max-height="350"
              >
                <el-table-column prop="name" label="Албан тушаал" width="150">
                  <template slot-scope="scope">
                    <el-input
                      disabled
                      size="mini"
                      placeholder="Менежер"
                      style="text-align: center"
                      v-model="scope.row.name"
                      controls-position="right"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="phone" label="Утас" width="150">
                  <template slot-scope="scope">
                    <el-input
                      disabled
                      size="mini"
                      placeholder="99119911"
                      style="text-align: center"
                      type="number"
                      v-model.number="scope.row.phone"
                    ></el-input>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
            <el-form-item>
              <span class="dialog-footer">
                <el-button type="primary" @click="confirm(editPartner)"
                  >Зөвшөөрөх</el-button
                >
                <el-button @click="isDetail = false">
                  Буцах
                </el-button>
              </span>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="dialogVisible"
      width="30%"
      :title="'Харилцагч устгах'"
      :before-close="handleClose"
      v-if="deleteData"
    >
      <span
        >Та <b> {{ deleteData.outlet_name }}</b> гэсэн харилцагчийг устгах гэж
        байна!!!</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">Болих</el-button>
        <el-button type="primary" @click="deletePartner">Устгах</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import services from "../helpers/services";
import { getUserName } from "../utils/auth";
export default {
  mounted() {
    this.getServices();
    this.newPartners();
    getUserName().then(name => {
      this.user = name.attributes.email.split("@")[0];
    });
  },
  data() {
    return {
      servicesList: [],
      partnerList: [],
      isDetail: false,
      editPartner: null,
      deleteData: null,
      dialogVisible: false,
      count: 0,
      user: "",
      logs: [],
      historyVisible: false,
      size: 10,
      from: 0,
      outletName: "",
      email: ""
    };
  },
  methods: {
    handleItem(file) {
      window.open(file);
    },
    // setPage(from) {
    //   this.from = from;
    //   this.newPartners();
    // },
    // setPageSize(size) {
    //   this.size = size;
    //   this.newPartners();
    // },
    newPartners() {
      services.getNewPartners(this.outletName, this.email).then(response => {
        this.partnerList = response.data.data.data;
        this.count = response.data.data.total;
      });
    },
    detail(data) {
      this.editPartner = Object.assign({}, data);
      this.isDetail = true;
      const servicesOutlet = [];
      if (
        this.editPartner.service_list &&
        this.editPartner.service_list.length > 0
      ) {
        this.editPartner.service_list.map(elOutlet => {
          this.servicesList.map(el => {
            if (el.name_eng === elOutlet) servicesOutlet.push(el.name_mon);
          });
        });
      }
      this.editPartner.services = servicesOutlet.toString();
    },
    confirm(data) {
      data.user = this.user;
      services.confirmNewPartner(data._id, data).then(response => {
        if (response.data.data.message === "Амжилттай засагдлаа...") {
          this.newPartners();
          this.$forceUpdate();
          this.isDetail = false;
          this.$notify({
            title: "Амжилттай",
            message: response.message,
            type: "success"
          });
        }
      });
    },
    deleteDialog(row) {
      this.dialogVisible = true;
      this.deleteData = row;
    },
    deletePartner() {
      services.deleteNewPartner(this.deleteData._id).then(response => {
        if (response.data.status === "success") {
          this.newPartners();
          this.$forceUpdate();
          this.dialogVisible = false;
          this.$notify({
            title: "Амжилттай",
            message: response.message,
            type: "success"
          });
        }
      });
    },
    handleClose() {
      this.dialogVisible = false;
    },
    close() {
      this.isDetail = false;
    },
    getServices() {
      services.getServices(false, "active", true).then(servicesResponse => {
        let b = servicesResponse.data.data.map(el => {
          el.name_eng = el.name_eng.toLowerCase();
          el.name_mon = el.name_mon.toLowerCase();
          return el;
        });
        this.servicesList = b;
      });
    }
  }
};
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 158px;
  height: 158px;
  line-height: 158px;
  text-align: center;
}
.avatar {
  cursor: pointer;
  width: 158px;
  height: 158px;
  display: block;
}
</style>
